/* eslint-disable react/no-unstable-nested-components */
import React, {
  CSSProperties,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { react2angular } from 'react2angular';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { IShipmentListItem } from 'typings/shipment';
import { ArrowTooltip, Pulse } from 'easyship-components';
import { twMerge } from 'tailwind-merge';
import { TaxDutyCell } from '@client/src/manage-shipments/ShipmentsTable/TaxDutyCell';
import { ShipmentGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentGatewayProvider';
import useShipmentsTableSettingsQuery from '@client/src/manage-shipments/hooks/queries/useShipmentTableSettingsQuery';
import { ShipmentsTableSettingsGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentsTableSettingsGatewayProvider';
import { ColumnVisibilityMap } from '@typings/dashboard/shipment_table/settings';
import { FormattedMessage } from 'react-intl';
import TaxesOptionPopover from '@client/core/components/react/TaxesOptionPopOver';
import { Info } from 'easyship-components/icons';
import ShipmentsTableContainer from './ShipmentsTableContainer';
import ShipmentsTableHeader from './ShipmentsTableHeader';
import ShipmentsTableHeaderCell from './ShipmentsTableHeaderCell';
import ShipmentsTableSelectAll from './ShipmentsTableSelectAll';
import ShipmentsTableRow from './ShipmentsTableRow';
import ShipmentsTableRowCell from './ShipmentsTableRowCell';
import { OrderCell } from './OrderCell';
import { CourierCell } from './CourierCell';
import { RemarkCell } from './RemarkCell';
import { HandoverCell } from './HandoverCell';
import { TrackingCell } from './TrackingCell';
import { TotalCostCell } from './TotalCostCell';
import { ReceiverCell } from './ReceiverCell';
import { TagsCell } from './TagsCell';
import { PackageCell } from './PackageCell';
import { ItemsCell } from './ItemsCell';
import { getMapOfAllColumns, ShipmentsTableColumn } from '../ShipmentsTableColumnSettings/utils';
import { ScrollingTableProvider } from '../providers/ScrollingTableProvider';
import CollectStatusCell from './CollectStatusCell';
import ShipmentCellSkeleton from './ShipmentCellSkeleton';
import RefreshCell from './RefreshCell';
import { ShipmentState } from './d';

const ToolTipTracking = lazy(() => import('./ToolTipTracking'));

export interface ShipmentsTableProps {
  shipments: IShipmentListItem[];
  isShipmentsLoading: boolean;
  totalShipmentsCount: number;
  openShipmentIndex: number;
  selectedShipmentsCount: number;
  onHeaderCheckboxChanged: (checked: boolean) => void;
  onAllShipmentsSelected: () => void;
  onAllShipmentsDeselected: () => void;
  onShipmentCheckboxChanged: (checked: boolean, index: number) => void;
  onOrderCellClick: (_: MouseEvent | null, index: number) => void;
  onReceiverCellClick: (_: MouseEvent | null, index: number) => void;
  onCourierCellClick: (_: MouseEvent | null, index: number) => void;
  onRefreshCellClick: () => void;
}

interface HeaderCellProps {
  children?: React.ReactNode | string;
  className?: string;
  labelKey?: string;
}

function FlexGrowContainer({ children, className, labelKey }: HeaderCellProps) {
  return (
    <div className={twMerge('flex flex-grow h-full p-2 items-center', className)}>
      {children || <FormattedMessage id={`shipments.list.headers.${labelKey}`} />}
    </div>
  );
}

const HEADER_TABLE = [
  {
    id: 'selected',
    accessorFn: (row: Record<'selected', boolean>) => Boolean(row.selected),
    width: 32,
    minWidth: 32,
    maxWidth: 32,
  },
  {
    id: ShipmentsTableColumn.ORDER,
    header: () => <FlexGrowContainer className="min-w-[152px] order-cell" labelKey="order" />,
    minWidth: 152,
    style: {
      position: 'sticky',
      background: 'white',
      left: '42px',
      zIndex: '5',
    },
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return <OrderCell row={row} />;
    },
  },
  {
    id: ShipmentsTableColumn.PACKAGE,
    minWidth: 152,
    header: () => <FlexGrowContainer className="min-w-[152px]" labelKey="package" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <PackageCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.ITEMS,
    minWidth: 152,
    header: () => <FlexGrowContainer className="min-w-[152px]" labelKey="items" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <ItemsCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.RECEIVER,
    minWidth: 152,
    header: () => <FlexGrowContainer className="min-w-[152px]" labelKey="receiver" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <ReceiverCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.COURIER,
    minWidth: 188,
    header: () => (
      <FlexGrowContainer className="min-w-[188px] gap-1 cursor-pointer">
        <FormattedMessage id="shipments.list.headers.courier" />
        <ArrowTooltip
          className="inline-flex items-center"
          maxWidth="default"
          placement="bottom"
          label={
            <div className="text-[13px]">
              <FormattedMessage id="shipments.list.courier.tooltip-h-content" />
            </div>
          }
        >
          <div className="flex items-center text-ink-100">
            <Info />
          </div>
        </ArrowTooltip>
      </FlexGrowContainer>
    ),
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <RefreshCell />
      ) : (
        <CourierCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.TAGS,
    minWidth: 152,
    header: () => <FlexGrowContainer className="min-w-[152px]" labelKey="tags" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <TagsCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.TRACKING,
    minWidth: 156,
    header: () => (
      <FlexGrowContainer className="min-w-[156px] gap-1 cursor-pointer">
        <FormattedMessage id="shipments.list.headers.tracking" />

        <ArrowTooltip
          className="inline-flex items-center"
          maxWidth="extra-wide"
          placement="bottom"
          label={
            <Suspense
              fallback={
                <div className="w-[360px] h-[480px]">
                  <Pulse show />
                </div>
              }
            >
              <ToolTipTracking />
            </Suspense>
          }
        >
          <div className="flex items-center text-ink-100">
            <Info />
          </div>
        </ArrowTooltip>
      </FlexGrowContainer>
    ),
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <TrackingCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.HANDOVER,
    minWidth: 100,
    header: () => <FlexGrowContainer className="min-w-[100px]" labelKey="handover" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <HandoverCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.REMARKS,
    minWidth: 120,
    header: () => <FlexGrowContainer className="min-w-[120px]" labelKey="remarks" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <RemarkCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.COLLECT_STATUS,
    minWidth: 132,
    header: () => <FlexGrowContainer className="min-w-[132px]" labelKey="collect-status" />,
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return <CollectStatusCell state={row.original.collect_state} />;
    },
  },
  {
    id: ShipmentsTableColumn.TAX_DUTY,
    minWidth: 178,
    header: () => (
      <FlexGrowContainer className="min-w-[178px] gap-1 cursor-pointer">
        <FormattedMessage id="shipments.list.headers.import-tax" />
        <ArrowTooltip
          className="inline-flex items-center"
          maxWidth="extra-wide"
          placement="bottom"
          label={<TaxesOptionPopover />}
        >
          <div className="flex items-center text-ink-100">
            <Info />
          </div>
        </ArrowTooltip>
      </FlexGrowContainer>
    ),
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <TaxDutyCell row={row} />
      );
    },
  },
  {
    id: ShipmentsTableColumn.TOTAL_COST,
    minWidth: 104,
    header: () => <FlexGrowContainer className="min-w-[104px] justify-end" labelKey="total-cost" />,
    // eslint-disable-next-line react/no-unused-prop-types
    cell: ({ row }: { row: Row<IShipmentListItem> }) => {
      return row.original.shipment_state === ShipmentState.Calculating ? (
        <ShipmentCellSkeleton />
      ) : (
        <TotalCostCell row={row} />
      );
    },
  },
];

export function ShipmentsTable({
  shipments: newShipmentData,
  isShipmentsLoading,
  totalShipmentsCount: totalShipmentsCountEntry,
  openShipmentIndex,
  selectedShipmentsCount: selectedShipmentsCountEntry,
  onHeaderCheckboxChanged,
  onAllShipmentsSelected,
  onAllShipmentsDeselected,
  onShipmentCheckboxChanged,
  onOrderCellClick,
  onReceiverCellClick,
  onCourierCellClick,
  onRefreshCellClick,
}: ShipmentsTableProps) {
  const [shipmentsData, setShipmentsData] = useState({
    shipments: [] as IShipmentListItem[],
    totalShipmentsCount: 0,
    selectedShipmentsCount: 0,
  });

  const { shipments, totalShipmentsCount, selectedShipmentsCount } = shipmentsData;

  const { data: visibleColumns = {} as ColumnVisibilityMap } = useShipmentsTableSettingsQuery();

  useEffect(() => {
    if (!isShipmentsLoading) {
      setShipmentsData({
        shipments: [...newShipmentData],
        totalShipmentsCount: totalShipmentsCountEntry,
        selectedShipmentsCount: selectedShipmentsCountEntry,
      });
    }
  }, [isShipmentsLoading, newShipmentData, selectedShipmentsCountEntry, totalShipmentsCountEntry]);

  const shipmentsCount = shipments.length;
  const isShipmentsEmpty = shipmentsCount === 0;

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const getCellClickHandler = useCallback(
    (columnId: string, index: number) => {
      switch (columnId) {
        case ShipmentsTableColumn.COURIER:
        case ShipmentsTableColumn.TRACKING:
        case ShipmentsTableColumn.HANDOVER:
        case ShipmentsTableColumn.TOTAL_COST:
        case ShipmentsTableColumn.TAX_DUTY:
          onCourierCellClick(null, index);
          break;

        case ShipmentsTableColumn.RECEIVER:
          onReceiverCellClick(null, index);
          break;

        default:
          onOrderCellClick(null, index);
      }
    },
    [onCourierCellClick, onReceiverCellClick, onOrderCellClick]
  );

  const biggestTotalCost = useMemo<number>(
    () =>
      visibleColumns.total_cost_column
        ? Math.max(
            ...shipments
              .filter(({ total_charge: totalCost }) => totalCost !== null)
              .map(({ total_charge: totalCost }) => totalCost as number)
          )
        : 0,
    [shipments, visibleColumns]
  );

  const columns = useMemo<ColumnDef<IShipmentListItem>[]>(() => {
    const totalWidthNeeded =
      HEADER_TABLE.reduce((acc, x) => {
        const { minWidth, width = 100 } = x;
        return acc + (minWidth || width);
      }, 0) + 60;

    return HEADER_TABLE.map((d) => {
      const { maxWidth, width, ...rest } = d;
      let { minWidth } = d;

      if (d.id === ShipmentsTableColumn.TOTAL_COST) {
        if (biggestTotalCost >= 10_000) {
          minWidth = 138;
        } else if (biggestTotalCost >= 1_000) {
          minWidth = 128;
        }
      }

      const widthRel =
        width || !minWidth ? 0 : Math.round(((minWidth || 100) * 100) / totalWidthNeeded);

      const style = {
        ...(widthRel
          ? {
              width: `${widthRel}%`,
            }
          : {}),
        ...(width
          ? {
              maxWidth: `${width}px`,
              minWidth: `${width}px`,
              width: `${width}px`,
            }
          : {}),
        ...(minWidth
          ? {
              minWidth: `${minWidth}px`,
            }
          : {}),
        ...(maxWidth
          ? {
              maxWidth: `${maxWidth}px`,
            }
          : {}),
        ...(d.style || {}),
      };

      return {
        ...rest,
        style,
      };
    });
  }, [biggestTotalCost]);

  const table = useReactTable<IShipmentListItem>({
    data: shipments,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        selected: false,
        ...getMapOfAllColumns(),
        ...visibleColumns,
      },
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    table.setColumnVisibility({
      selected: false,
      ...getMapOfAllColumns(),
      ...visibleColumns,
    });
  }, [table, visibleColumns]);

  const toggleAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onHeaderCheckboxChanged(checked);
  };

  const toggleSelectAll = () => {
    if (!totalShipmentsCount) return;
    const isAllSelected = selectedShipmentsCount === totalShipmentsCount;
    if (!isAllSelected) {
      onAllShipmentsSelected();
    } else {
      onAllShipmentsDeselected();
    }
  };

  const toggleRow = (event: React.ChangeEvent<HTMLInputElement>, row: Row<IShipmentListItem>) => {
    const { checked } = event.target;
    onShipmentCheckboxChanged(checked, row.index);
  };
  const [isAllSelectedOnPage, isSelectedPartiallyOnPage] = useMemo(() => {
    const isAll = shipments.every((s) => s.selected);
    const isPart = !isAll && !!shipments.find((s) => s.selected);
    return [isAll, isPart];
  }, [shipments]);

  if (!totalShipmentsCount) {
    return (isShipmentsLoading && <Pulse show />) || null;
  }

  return (
    <ScrollingTableProvider>
      <ShipmentsTable.Container>
        {/* ------ Header ------ */}
        {table.getHeaderGroups().map((headerGroup) => (
          <ShipmentsTable.Header
            key={headerGroup.id}
            isCheckboxChecked={isAllSelectedOnPage || isSelectedPartiallyOnPage}
            isPartialSelected={isSelectedPartiallyOnPage}
            onCheckboxChanged={toggleAllRows}
          >
            {headerGroup.headers.map((header) => (
              <ShipmentsTable.HeaderCell
                key={header.id}
                style={
                  (
                    header.column.columnDef as unknown as {
                      style: CSSProperties;
                    }
                  ).style
                }
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </ShipmentsTable.HeaderCell>
            ))}
          </ShipmentsTable.Header>
        ))}

        {isShipmentsLoading && (
          <div className={twMerge('py-[190px]', isShipmentsEmpty ? 'relative' : 'fixed w-full')}>
            <Pulse show />
          </div>
        )}

        {!isShipmentsEmpty && (
          <>
            <ShipmentsTable.SelectAll
              selectedCount={selectedShipmentsCount}
              totalCount={totalShipmentsCount}
              toggleSelection={toggleSelectAll}
            />

            <div
              data-test-id="shipments-table-body"
              className="flex flex-col w-fit min-w-full gap-px rounded"
            >
              {/* ------ Rows ------ */}
              {table.getRowModel().rows.map((row) => (
                <ShipmentsTable.Row
                  key={row.original.id}
                  index={row.index}
                  isFirst={row.index === 0}
                  isLast={row.index === shipmentsCount - 1}
                  isOpened={row.index === openShipmentIndex}
                  isCheckboxChecked={!!row.original?.selected}
                  onCheckboxChanged={(event) => toggleRow(event, row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <ShipmentsTable.RowCell
                        key={cell.id}
                        onClick={
                          row.original.shipment_state === ShipmentState.Calculating &&
                          cell.column.id === ShipmentsTableColumn.COURIER
                            ? onRefreshCellClick
                            : () => getCellClickHandler(cell.column.id, row.index)
                        }
                        rowIndex={row.index}
                        style={(cell.column.columnDef as unknown as { style: CSSProperties }).style}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </ShipmentsTable.RowCell>
                    );
                  })}
                </ShipmentsTable.Row>
              ))}
            </div>
          </>
        )}
      </ShipmentsTable.Container>
    </ScrollingTableProvider>
  );
}

ShipmentsTable.Container = ShipmentsTableContainer;
ShipmentsTable.Header = ShipmentsTableHeader;
ShipmentsTable.HeaderCell = ShipmentsTableHeaderCell;
ShipmentsTable.SelectAll = ShipmentsTableSelectAll;
ShipmentsTable.Row = ShipmentsTableRow;
ShipmentsTable.RowCell = ShipmentsTableRowCell;

function Wrapper(props: ShipmentsTableProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentGatewayProvider>
          <ShipmentsTableSettingsGatewayProvider>
            <ShipmentsTable {...props} />
          </ShipmentsTableSettingsGatewayProvider>
        </ShipmentGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularShipmentsTable = react2angular(
  Wrapper,
  [
    'shipments',
    'isShipmentsLoading',
    'totalShipmentsCount',
    'openShipmentIndex',
    'selectedShipmentsCount',
    'onHeaderCheckboxChanged',
    'onAllShipmentsSelected',
    'onAllShipmentsDeselected',
    'onShipmentCheckboxChanged',
    'onOrderCellClick',
    'onReceiverCellClick',
    'onCourierCellClick',
    'onRefreshCellClick',
  ],
  []
);
